<template>
  <div
    v-if="!hideText"
    class="text-container"
    :class="`${textOptions.position}`"
    :style="{
      'text-align': `${textOptions.placement}`,
      background: `${background}`,
    }"
  >
    <h3
      class="player-text"
      :style="{
        color: `${textOptions.color}`,
        'font-size': `${textOptions.size}px`,
        'line-height': `${textOptions.size}px`,
        'font-weight': `${isBold}`,
        'font-style': `${isItalic}`,
        'text-decoration': `${isUnderlined}`,
      }"
    >
      {{ decodeTextPlayer(textOptions.text) }}
    </h3>
  </div>
</template>
  <script>
import { mapGetters } from "vuex";
import { decodeText } from "../../utils/utils";
export default {
  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
  },

  mounted() {},

  computed: {
    ...mapGetters("videos", ["getVideoByIndex"]),
    ...mapGetters("player", ["isMobile"]),
    ...mapGetters("playerCampaign", ["format"]),

    hideText() {
      return this.isMobile && this.format?.carouselThumbnailAmount === 2;
    },

    textOptions() {
      return this.getVideoByIndex(this.videoIndex).textOptions;
    },
    background() {
      if (this.textOptions.position === "end")
        return `linear-gradient(180deg, rgba(255, 0, 0, 0.00) 0%, ${this.textOptions.backgroundColor} 100%)`;
      return `linear-gradient(180deg, ${this.textOptions.backgroundColor} 0%, rgba(255, 0, 0, 0.00) 100%)`;
    },
    isBold() {
      if (this.textOptions.isBold) {
        return "600";
      }
      return "500";
    },
    isItalic() {
      if (this.textOptions.isItalic) {
        return "italic";
      }
      return "normal";
    },
    isUnderlined() {
      if (this.textOptions.isUnderlined) {
        return "underline";
      }
      return "none";
    },
  },
  methods: {
    decodeTextPlayer(string) {
      return decodeText(string);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.start {
  top: 0;
}
.end {
  bottom: 0;
}
.text-container {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  touch-action: none;
  width: 100%;
  border-radius: 10px;
}

.player-text {
  padding: 16px;
  font-size: 16px;
  overflow: hidden;
}

@include media("<=phone") {
  .player-text {
    font-size: 14px;
  }
}
</style>